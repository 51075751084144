import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const DeleteTestCase = () => {
  return (
    <LayoutComponent>
      <h1>Deleting Test Cases</h1>

      <p>
        To maintain a clean and organized test library, you may need to delete
        outdated or unnecessary test cases. Before you can delete a test case,
        you must first archive it.
      </p>

      <div class="testimonials">
        <h5>Note</h5>
        <p>
          Test cases that are part of a test cycle or have dependencies cannot
          be deleted. You can only delete one page of test cases at a time to
          minimize impact on your instance.
        </p>
      </div>

      <h5>Steps to Delete a Test Case</h5>
      <p>Follow these steps to delete an archived test case:</p>
      <ol>
        <li>
          Navigate to the <strong>Archived Test Cases</strong> section.
        </li>
        <li>Select the test cases you wish to delete.</li>
        <li>
          Click the <strong>Delete</strong> button to initiate the deletion
          process.
        </li>
        <div className="testimonials">
          <h5>Note</h5>
          <p>
            This action cannot be undone. Ensure that you have reviewed all
            associated data before confirming the deletion.
          </p>
        </div>
        <li>
          A confirmation prompt will appear, warning you that deleting the test
          case(s) will permanently remove all related data, including test
          steps, executions, comments, and attachments. Confirm if you want to
          proceed.
        </li>
        <li>
          The deletion process may take some time, depending on the number of
          test cases, to ensure all related data is thoroughly removed.
        </li>
        <li>The deletion process is now complete!</li>
      </ol>
    </LayoutComponent>
  )
}

export default DeleteTestCase
